import React from 'react'
import AbouMe from './AboutMe.css'

function AboutMe() {
  return (
    <div>
      <div className='AbMeT' id='AboutMePage'>
        <h3>About me</h3>
      </div>
      <div className='AboutMe'>
        <p id='biography'>Highly motivated front-end developer with a passion for programming and a strong desire to gain experience in the industry. Proficient in Java, JavaScript, and React, with experience in HTML, CSS, Git, and GitHub. English and german C1. Quick learner and eager to contribute to the success of your company by helping to develop and improve web applications. Seeking opportunities to work with real code and become part of a dynamic IT team to grow further and bring new ideas to the table.</p>
      </div>
    </div>
  )
}

export default AboutMe