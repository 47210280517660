import React from 'react'
import HomePageStyles from './HomePageStyles.css'

function HomePage() {
  return (
    <div className='HomePage'>
        <div className='Greeting'>
            <h3 className='MyName'>Moin! My name is <span className='GradientPart'>Georgy Kuznetsov</span></h3>
        </div>
        <div className='Description'>
        <p>I'm a web developer from Hamburg and here you can know more about me!</p>
        </div>
    </div>
  )
}

export default HomePage