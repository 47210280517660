import header from './header.css'
import React from 'react'

function Header() {
  return (
    <div>
        <div className='header' id='HomePage'>

            <div className="header-left-logo">
                <a href="https://github.com/gororosha" id='gitImg'>
                    <img src="github-mark-white.svg" width={40} alt="logo"></img>
                </a>
                <h3 id='header-name'>Georgy Kuznetsov</h3>
            </div>

            <div className="header-right-links">
                <a href="#HomePage" id='Home'>Home</a>
                <a href="#AboutMePage" id='About'>About</a>                
                <a href="#ContactMePage" id='Contact'>Contact</a>
            </div>  

        </div>      
    </div>
  )
}

export default Header