import React from 'react'
import ContactMeStyles from './ContactMeStyles.css'

function ContactMe() {
  return (
    <div className='ContactMe' id='ContactMePage'>
        <h3 className='mytitel'>Contact me:</h3>
        <div className='mydata'>
          <img src='Download.png' width={25} id='contactimg'></img>
          <span id='telephone'>+49 172 38 98 492</span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <img src='mail.png' width={25} id='contactimg'></img>
          <span id='mail'>geokuzn@gmail.com</span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <img src='adress.png' width={25} id='contactimg'></img>
          <span id='adress'>Graf-Johann-Weg 19, 22459 Hamburg</span>
        </div>
    </div>
  )
}

export default ContactMe