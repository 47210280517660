import React from 'react'
import ProgStyle from './ProgStyle.css'

function Progress() {
  return (
    <div>
        <div class="contentContainer">

            <div class="skillBar">
                <h4>HTML</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-90"></div>
                </div>
            </div>

            <div class="skillBar">
                <h4>CSS3</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-80"></div>
                </div>
            </div>
            
            <div class="skillBar">
                <h4>JavaScript</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-60"></div>
                </div>
            </div>

            <div class="skillBar">
                <h4>React.js</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-40"></div>
                </div>
            </div>
            
            <div class="skillBar">
                <h4>Java</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-40"></div>
                </div>
            </div>

            <div class="skillBar">
                <h4>Git</h4>
                <div class="skillBarContainer">
                <div class="skillBarValue value-80"></div>
                </div>
            </div>

        </div>
        
    </div>
  )
}

export default Progress